import siteLogo from '../../assets/images/logo.png'
import './header.scss'

const Header = () => {
	return (
		<>		
				<header className='header'>
					<div className='logo'>
						<img src={siteLogo} alt='Etihad' title='Etihad' />
					</div>
                </header>
        </>
    )
    }

export default Header