import './App.css';
import LoadBackground from './components/Loadapi/LoadBackground.js';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
  
function App() {
  return (
    <div className="App">
      <Header/>
      <LoadBackground/>
      <Footer/>
    </div>
  );
}

export default App;
